import React from 'react';
import '../styles/NotFound.css'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar';
import blogForgetpage from '../Assets/Images/Frame 62.png';
import NotPage from '../Assets/Images/errorimg.png';

const NotFound = () => {

  return (
    <>

<Navbar navbar2='true' />
    <div id='homesection1container'>
    <div className="homesection1container">
        <div className="homesection1">
        <img id='forgetMobile' src={blogForgetpage} alt="card"/>
         <img id='homepage' src={NotPage} alt="card"/>
          <div className='homesection1textcontainer'>
            <div className='homesection1heading'>
              <h1>404 Error: PAGE NOT FOUND </h1> 
              <p>We're as surprised as you are! While we untangle this digital mystery, please <br/> head back to the homepage to start your journey afresh. <span><Link to='/'>Back to homepage</Link></span></p> 
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default NotFound
