import React from 'react';
import '../styles/AboutUs.css';
import ghanaHome from '../Assets/Images/ghanaHome.svg';

const AboutusCard = ({heading, text1, text2, img = false, bgColor = false}) => {


  return (
    <div>
        <div className={bgColor? "aboutUs_section1_column1Color" :'aboutUs_section1_column1'}>
          <div className='aboutUs_section1_column1_flex'>
            <h3 className='aboutUs_section1_column1_flexh3'>
                {text1}
            </h3>
            {
              img ? (
                <p className='aboutUs_section1_column1_flexp'>
                  <img src={ghanaHome} alt='ghanaHome' />
                </p>

              ): (
                <p className='aboutUs_section1_column1_flexp'>
                {text2}
              </p>
              )
            }
             
          </div>

        </div>
    </div>
  )
}

export default AboutusCard;