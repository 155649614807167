import React from 'react';
import '../styles/LearnMoreBtn.css';

const LearnMoreBtn = ({btn_text, btn_bg=false}) => {

  return (
    <>
    <div className={btn_bg? "LearnMoreBtn_containerGhana": 'LearnMoreBtn_container' }>
      <p className='LearnMoreBtn_containerp'>{btn_text}</p>
    </div>
    
    </>
  )
}

export default LearnMoreBtn